<template>
  <b-card>
    <table-render :schema="schema" :rows="rows" :actions ="actions">

    </table-render>
    <modal-detail-priority></modal-detail-priority>
  </b-card>
</template>
<script>
import ModalDetailPriority from './components/ModalDetail.vue'
export default {
  components: {
    ModalDetailPriority
  },
  data() {
    return {
      schema: [],
      rows: [],
      actions: []
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.schema = [
        {label: 'País', key: 'country'},
        {label: 'Tipo de Negocio', key: 'typeCompany'},
        {label: 'Categoría', key: 'category'},
        {label: 'Acciones', key: 'actions', class: ['text-center thead--light']}
      ]
      this.actions = [
        {action: id => this.openDetail(id), icon: 'SettingsIcon', color:'primary', text: 'Abrir configuración'}
      ]
      this.rows = [{
        id: '1',
        country: 'Chile',
        typeCompany: 'Distribución',
        category: 'Una categoría'
      }]
    },
    openDetail(id) {
      this.$bvModal.show('modalDetailPriority')

    }
  }
}
</script>