<template>
  <b-modal id="modalDetailPriority" :title="'Configuración'" size="lg" centered text class="" ok-only ok-title="Buscar" ok-variant="warning" hide-footer>
    <b-card>

    </b-card>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>